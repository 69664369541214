import React, { useEffect, useState } from "react";
import homeImage from "../assets/images/home.jpg"; // Adjust the path if necessary
import { Mouse } from "lucide-react";

const FirstSection = ({
  backgroundImage = homeImage,
  children,
  height = " min-h-[100vh]  ",
  className = "",
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`${height} text-center bg-cover bg-no-repeat bg-center flex flex-col`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className={`${height} w-full  flex flex-col justify-between`}
        style={{
          background: `linear-gradient(0deg, rgba(4, 7, 22, 0.4), rgba(4, 7, 22, 0.4)), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`,
        }}
      >
        <div
          className={`flex flex-col items-center justify-center pt-20 pb-20 w-full  flex-grow ${className}`}
        >
          {children}
        </div>
        <div
            className={`p-5 w-[100vw] flex justify-center transition-opacity duration-500 fixed bottom-0 ${
              isVisible ? "opacity-100" : "opacity-0"
            }`}
            style={{ pointerEvents: isVisible ? "auto" : "none" }}
          >
          <div
            className={`bg-white/10 flex justify-center h-12 w-12 items-center rounded-full scroll-down `}
          >
            <Mouse className="size-6 text-white arrow relative" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstSection;
