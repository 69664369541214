// src/components/Footer.js
import React, { useEffect, useState , useRef } from "react";
import Video from "yet-another-react-lightbox/plugins/video";
import { Reveal } from "../utils/Reveal";

import { ArrowUp, CalendarDays, ExternalLink } from "lucide-react";
import "swiper/css";
import Lightbox from "yet-another-react-lightbox";
import CapLogo from "../assets/cap-logo";
import MiddoLogo from "../assets/middo-logo";
import NufiLogo from "../assets/nufi-logo";
import BlueCard from "../utils/BlueCard";
import FirstSection from "../utils/FullScreenSection";
import ThumbnailViewer from "../utils/ThumbnailViewer";

import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useScroll, useScrollContext } from "../components/Header";
import {
  TabProduct_CAPDocuments,
  TabProduct_CAP,
  TabProduct_MiddoDocuments,
  TabProduct_Middo,
  TabProduct_NufiDocuments,
  TabProduct_Nufi,
} from "../mediaData";

const ProductPage = () => {
  // const [controls, setControls] = useState(true);
  // const [playsInline, setPlaysInline] = useState(true);
  // const [autoPlay, setAutoPlay] = useState(true);
  // const [loop, setLoop] = useState(false);
  // const [muted, setMuted] = useState(false);
  // const [disablePictureInPicture, setDisablePictureInPicture] = useState(false);
  // const [disableRemotePlayback, setDisableRemotePlayback] = useState(false);
  // const [controlsList, setControlsList] = useState([]);
  // const [crossOrigin, setCrossOrigin] = useState("");
  // const [preload, setPreload] = useState("");

  const [indexVideoNufi, setIndexVideoNufi] = useState(-1);
  const [indexVideoMiddo, setIndexVideoMiddo] = useState(-1);
  const [indexVideoCAP, setIndexVideoCAP] = useState(-1);
  const { isScrolled } = useScroll();
  const { nufiRef, middoRef, capRef, scrollToSection } = useScrollContext();
  const location = useLocation();
  const [hasScrolled, setHasScrolled] = useState(false);


  // useEffect(() => {
  //   const hash = location.hash.replace("#", ""); // URL에서 해시(#) 부분만 가져오기
  //   if (hasScrolled) return;
  
  //   if (hash === "nufi" && nufiRef.current) {
  //     nufiRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     setHasScrolled(true);
  //   } else if (hash === "middo" && middoRef.current) {
  //     middoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     setHasScrolled(true);
  //   } else if (hash === "cap" && capRef.current) {
  //     capRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     setHasScrolled(true);
  //   }
  // }, [location, nufiRef, middoRef, capRef, hasScrolled]);
  useEffect(() => {
    const hash = location.hash.replace("#", "").replace("/", "");
    if (hash === "nufi" && nufiRef.current) {
      nufiRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (hash === "middo" && middoRef.current) {
      middoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (hash === "CAP" && capRef.current) {
      capRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location.hash, nufiRef, middoRef, capRef]);

  return (
    <div className=" w-full">
      <div
        className={`fixed z-10 right-2 bottom-2 justify-center flex items-center border md:hover:bg-neutral-25 active:bg-neutral-100 border-neutral-200 bg-white text-black size-10 rounded-full transition-opacity duration-500 delay-300 ${
          !isScrolled && "opacity-0"
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <ArrowUp size={20} />
      </div>
      {/* First Section */}
      <FirstSection
        className=" lg:h-[100vh] lg:min-h-[720px] h-fit flex flex-col gap-10 pt-10"
        height="lg:h-[100vh] min-h-[720px]"
      >
        <Reveal className="pt-5 gap-5 flex flex-col px-[5vw]">
          <h1 className="text-4xl md:text-5xl font-bold text-white lg:text-6xl">
            <span className="text-blue-500">O</span>ur Products{" "}
          </h1>
        </Reveal>
        <Reveal className="flex flex-col px-[5vw] gap-10 lg:grid md:grid lg:grid-cols-3">

          {/* NuFi Card */} 
          <BlueCard
            className="gap-10 flex flex-col justify-center w-full max-w-[500px] min-w-[310px]"
            padding="5"
            height="[284px]"
          >
            <div className="gap-5 flex flex-col ">
              <div className="flex items-center h-fit align-middle gap-5">
                <NufiLogo />
                <div className="flex flex-col items-start text-start gap-3">
                  <h2 className="text-white font-[24px] weight-[700]">Nufi </h2>
                  <div className="gap-1 flex flex-row items-center">
                    <CalendarDays className="text-neutral-300 size-4" />
                    <p className="text-neutral-300 font-[24px] weight-[700] flex flex-row ">
                      {" "}
                      Since 2024{" "}
                    </p>
                  </div>
                </div>
              </div>

              <p className=" text-white text-xs font-normal text-left whitespace-pre-line">
                국산 AI 반도체(NPU)의 호환성을 확보한 플랫폼 {"\n"}
                NPU를 좀더 간편하게 사용할 수 있게 해줍니다.{"\n"}
                AI CHIP(NPU) based AI Inference Platform {"\n"}
                Provides all tools to develop AI with NPU
              </p>
            </div>
            <Link
              to="/product#nufi"
              className="explore-button h-11 w-[120px] rounded-xl bg-error-400 text-white flex items-center justify-center align-middle"
            >
              Explore
            </Link>
          </BlueCard>

          {/* Middo Card */} 
          <BlueCard
            className="gap-10 flex flex-col justify-center min-w-[310px]"
            padding="5"
            height="[284px]"
          >
            <div className="gap-5 flex flex-col ">
              <div className="flex items-center h-fit align-middle gap-5">
                <MiddoLogo />
                <div className="flex flex-col items-start text-start gap-3">
                  <h2 className="text-white font-[24px] weight-[700]">
                    Middo
                  </h2>
                  <div className="gap-1 flex flex-row items-center">
                    <CalendarDays className="text-neutral-300 size-4" />
                    <p className="text-neutral-300 font-[24px] weight-[700] flex flex-row ">
                      Since 2023{" "}
                    </p>
                  </div>
                </div>
              </div>

              <p className="text-white text-xs font-normal text-left whitespace-pre-line">
                실시간 E.S.L 번역을 지원하는 커뮤니케이션 앱 {"\n"}
                Extension, Bot을 통한 LLM 또한 지원합니다. {"\n"} 
                Global communication application with {"\n"} 
                real-time ESL translation and LLM support 
              </p>
            </div>
            <Link
              to="/product#middo"
              className="explore-button h-11 w-[120px] rounded-xl bg-error-400 text-white flex items-center justify-center align-middle"
            >
              Explore
            </Link>
          </BlueCard>

          {/* CAP Card */} 
          <BlueCard
            className="gap-10 flex flex-col justify-center min-w-[310px]"
            padding="5"
            height="[284px]"
          >
            <div className="gap-5 flex flex-col ">
              <div className="flex items-center h-fit align-middle gap-5">
                <CapLogo />
                <div className="flex flex-col items-start text-start gap-3">
                  <h2 className="text-white font-[24px] weight-[700]">
                    CAP
                  </h2>
                  <div className="gap-1 flex flex-row items-center">
                    <CalendarDays className="text-neutral-300 size-4" />
                    <p className="text-neutral-300 font-[24px] weight-[700] flex flex-row ">
                      Since 2020{" "}
                    </p>
                  </div>
                </div>
              </div>

              <p className="text-white text-xs font-normal text-left whitespace-pre-line">
                쿠버네티스 기반 End to End MLOps 솔루션 {"\n"}
                AI 연구와 서비스 개발에 몰두할수 있게 해줍니다 {"\n"}
                Cloud Based AI Platform with End to End MLOps {"\n"}
                working on Kubernetes
              </p>
            </div>
            <Link
              to="/product#CAP"
              className="h-11 w-[120px] rounded-xl bg-error-400 text-white flex items-center justify-center align-middle"
            >
              Explore
            </Link>
          </BlueCard>
        </Reveal>
      </FirstSection>
      {/* Nufi product */}
      <section className="relative lg:px-[5vw] px-5 py-[38px] ">
        <div ref={nufiRef} id="nufi" className="absolute h[0.1px] w[0.1px] -top-[80px]"></div>

        <div className="h-full w-full border border-neutral-100 rounded-3xl">
          {/* Section header*/}
          <Reveal className="h-fit p-5 bg-neutral-25 flex md:flex-row flex-col items-left rounded-t-3xl  justify-between lg:gap-5 gap-3 ">
            {/* Logo on the left */}
            <div className="flex gap-5">
              <NufiLogo />
              <div className="flex flex-col gap-3 justify-center md:justify-start  text-start">
                <p className="text-white px-3 w-fit py-2 bg-[#3C4C8A] rounded-xl text-18-700 leading-[22px]">
                  Nufi{" "}
                </p>
                <p className="hidden md:block text-neutral-800 font-[18px] weight-[400] leading-[22px] text-left">
                  NPU-based AI Integrated Platform (friendly with FuriosaAI)
                </p>
                <div className="gap-1 flex flex-row items">
                  <CalendarDays className="text-neutral-300 size-4 mt-[3px]" />
                  <p className="text-neutral-300 font-[14px] weight-[700] flex flex-row ">
                    Published in 2024{" "}
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
            <a 
              href="https://nufi.onrender.com"
              className=" p-3 rounded-xl flex flex-row gap-2 text-primary-700 text-16-600-20 text-left self-center  hover-effect"
              rel="noopener noreferrer" 
              target="_blank"
            >
              Open <ExternalLink size={20} />
            </a>
            {/* Text describe div */}
          </Reveal>
          {/* Section body */}
          <Reveal className="flex  flex-col p-5 gap-3">
            <p className="text-24-600 text-neutral-600">Introduction</p>
            <p className="text-16-400-20 text-neutral-600 text-justify whitespace-pre-line">
              NuFi 는 한국 NPU(AI 반도체)와 호환성이 높은 솔루션입니다. {"\n"}
              개발 작업환경, 배포도구, 모니터링, 양자화 및 모델 최적화 도구, 모델zoo 등의 편의도구들을 제공합니다. {"\n"}
              NuFi is a solution compatible with Korean NPUs and AI semiconductors. {"\n"}
              It provides SDK,CLI, Workspace, deployment tools, monitoring, quantization tools, and a model zoo.
            </p>
          </Reveal>{" "}
          <Reveal className="flex flex-col p-5 gap-2">
            <p className="text-24-600 text-neutral-600">Contents</p>
            <div className="grid gap-5 py-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {TabProduct_Nufi.flatMap((item, itemIndex) =>
                item.sources.map((source, sourceIndex) => (
                  <ThumbnailViewer
                    key={`${itemIndex}-${sourceIndex}`}
                    src={item.isVideo ? source.thumbnail : source.src} // Use thumbnail for videos
                    isVideo={item.isVideo}
                    hoverAble={true}
                    size="w-full h-[180px] rounded-[20px]"
                    onClick={() => {
                      if (item.isVideo) {
                        window.open(source.src, "_blank", "noopener,noreferrer"); // Open YouTube video in a new tab
                      } else {
                        setIndexVideoNufi(sourceIndex + itemIndex); // Open image in lightbox
                      }
                    }}
                  />
                ))
              )}
            </div>

            {/* Lightbox to display full-size images */}
            {indexVideoNufi !== -1 && (
              <Lightbox
                open={indexVideoNufi >= 0}
                close={() => setIndexVideoNufi(-1)} // Correct state update to close the lightbox
                index={indexVideoNufi}
                slides={TabProduct_Nufi.flatMap((item) =>
                  item.sources.map((source) => ({
                    src: source.src,
                    type: item.isVideo ? "video" : "image",
                  }))
                )}
              />
            )}
          </Reveal>
          <Reveal className="p-5 flex flex-col gap-3">
            <p className="text-24-600 text-neutral-600">Documents</p>
            {TabProduct_NufiDocuments.map((item, index) => (
              <div className="w-fit">
                <Link
                  to={item.href}
                  target="_blank"
                  className="md:text-outline-hover  rounded-xl p-3 text-16-600-20 text-primary-700 w-fit "
                >
                  {item.title}
                </Link>
              </div>
            ))}
            <div className=" gap-5"></div>
          </Reveal>
        </div>
      </section>

      {/* Middo product */}
      <section className="relative lg:px-[5vw] px-5 py-[38px] ">
        <div 
          ref={middoRef}
          id="middo" 
          className="absolute h[0.1px] w[0.1px] -top-[80px]"
        >
        </div>{" "}
        <div className="h-full w-full border border-neutral-100 rounded-3xl">
          {/* Section header*/}
          <Reveal className="h-fit p-5 bg-neutral-25 flex md:flex-row flex-col items-left rounded-t-3xl  justify-between lg:gap-5 gap-3 ">
            {/* Logo on the left */}
            <div className="flex gap-5">
              <MiddoLogo />
              <div className="flex flex-col gap-3 justify-center md:justify-start  text-start">
                <p className="text-white px-3 w-fit py-2 bg-[#3C4C8A] rounded-xl text-18-700 leading-[22px]">
                  Middo{" "}
                </p>
                <p className="hidden md:block text-neutral-800 font-[18px] weight-[400] leading-[22px] text-left">
                  Global Communicate Application with RealTime E.S.L tranlsation
                </p>
                <div className="gap-1 flex flex-row items">
                  <CalendarDays className="text-neutral-300 size-4 mt-[3px]" />
                  <p className="text-neutral-300 font-[14px] weight-[700] flex flex-row ">
                    Published in 2023{" "}
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
            <a 
              href="https://middo.app"
              className=" p-3 rounded-xl flex flex-row gap-2 text-primary-700 text-16-600-20 text-left self-center  hover-effect"
              rel="noopener noreferrer" 
              target="_blank"
            >
              Open <ExternalLink size={20} />
            </a>
            {/* Text describe div */}
          </Reveal>
          {/* Section body */}
          <Reveal className="flex  flex-col p-5 gap-3">
            <p className="text-24-600 text-neutral-600">Introduction</p>
            <p className="text-16-400-20 text-neutral-600 text-justify whitespace-pre-line">
              Middo(미도) 앱은 모두가 모국어로 소통할 수 있게 해주는 커뮤니케이션 도구 입니다. {"\n"}
              그룹채팅, DM 등의 텍스트 채팅이 각자의 모국어로 번역되며, 화상회의, 파일 및 화면 공유, LLM 서비스도 지원합니다. {"\n"}
              The Middo app is a communication tool that enables everyone to communicate in their native language. {"\n"}
              It provides translated text chat for group chats and direct messages, along with support for video conferencing, file and screen sharing, and LLM services.
            </p>
          </Reveal>{" "}
          <Reveal className="flex flex-col p-5 gap-2">
            <p className="text-24-600 text-neutral-600">Contents</p>
            <div className="grid gap-5 py-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {TabProduct_Middo.flatMap((item, itemIndex) =>
                item.sources.map((source, sourceIndex) => (
                  <ThumbnailViewer
                    key={`${itemIndex}-${sourceIndex}`}
                    src={item.isVideo ? source.thumbnail : source.src} // Use thumbnail for videos
                    isVideo={item.isVideo}
                    hoverAble={true}
                    size="w-full h-[180px] rounded-[20px]"
                    onClick={() => {
                      if (item.isVideo) {
                        window.open(source.src, "_blank", "noopener,noreferrer"); // Open YouTube video in a new tab
                      } else {
                        setIndexVideoMiddo(sourceIndex + itemIndex); // Open image in lightbox
                      }
                    }}
                  />
                ))
              )}
            </div>

            {/* Lightbox to display full-size images */}
            {indexVideoMiddo !== -1 && (
              <Lightbox
                open={indexVideoMiddo >= 0}
                close={() => setIndexVideoMiddo(-1)} // Correct state update to close the lightbox
                index={indexVideoMiddo}
                slides={TabProduct_Middo.flatMap((item) =>
                  item.sources.map((source) => ({
                    src: source.src,
                    type: item.isVideo ? "video" : "image",
                  }))
                )}
              />
            )}
          </Reveal>
          <Reveal className="p-5 flex flex-col gap-3">
            <p className="text-24-600 text-neutral-600">Documents</p>
            {TabProduct_MiddoDocuments.map((item, index) => (
              <div className="w-fit">
                <Link
                  to={item.href}
                  target="_blank"
                  className="md:text-outline-hover  rounded-xl p-3 text-16-600-20 text-primary-700 w-fit "
                >
                  {item.title}
                </Link>
              </div>
            ))}
            <div className=" gap-5"></div>
          </Reveal>
        </div>
      </section>

      {/* CAP product */}
      <section className="relative  lg:px-[5vw] px-5 py-[38px]">
        <div 
          ref={capRef}
          id="CAP" 
          className="absolute h[0.1px] w[0.1px] -top-[80px]"
        >
        </div>{" "}
        <div className="h-full w-full border border-neutral-100 rounded-3xl">
          {/* Section header*/}
          <Reveal className="h-fit p-5 bg-neutral-25 flex md:flex-row flex-col items-left rounded-t-3xl  justify-between lg:gap-5 gap-3 ">
            {/* Logo on the left */}
            <div className="flex gap-5">
              <CapLogo />
              <div className="flex flex-col gap-3 justify-center md:justify-start  text-start">
                <p className="text-white px-3 w-fit py-2 bg-[#3C4C8A] rounded-xl text-18-700 leading-[22px]">
                  CAP{" "}
                </p>
                <p className="hidden md:block text-neutral-800 font-[18px] weight-[400] leading-[22px] text-left">
                  A cloud-based AI platform offering an end-to-end MLOps solution built on Kubernetes.
                </p>
                <div className="gap-1 flex flex-row items">
                  <CalendarDays className="text-neutral-300 size-4 mt-[3px]" />
                  <p className="text-neutral-300 font-[14px] weight-[700] flex flex-row ">
                    Published in 2024{" "}
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
            <p className=" md:hidden text-neutral-800 font-[18px] weight-[400] leading-[22px] text-left ">
              A cloud-based AI platform offering an end-to-end MLOps solution built on Kubernetes.
            </p>
            <a 
              href="https://doc.gocap.kr"
              className=" p-3 rounded-xl flex flex-row gap-2 text-primary-700 text-16-600-20 text-left self-center hover-effect"
              rel="noopener noreferrer" 
              target="_blank"
            >
              Open <ExternalLink size={20} />
            </a>
            {/* Text describe div */}
          </Reveal>
          {/* Section body */}
          <Reveal className="flex  flex-col p-5 gap-3">
            <p className="text-24-600 text-neutral-600">Introduction</p>
            <p className="text-16-400-20 text-neutral-600 text-justify whitespace-pre-line">
              CAP은 쿠버네티스를 사용하는 퍼블릭 클라우드, 프라이빗 클라우드에서 가장 잘 작동하는 솔루션입니다. {"\n"}
              AI 모델을 개발하는 환경부터, 하이퍼파라미터 자동 튜닝, 컨테이너 레지스트, CI/CD/CT 파이프라인 기능 등을 제공합니다. {"\n"}
              CAP is a solution that works best on both public and private clouds using Kubernetes. {"\n"}
              It provides everything from an AI model development environment to automated hyperparameter tuning, a container registry, and CI/CD/CT pipeline functionality.
            </p>
          </Reveal>{" "}
          <Reveal className="flex flex-col p-5 gap-2">
            <p className="text-24-600 text-neutral-600">Contents</p>
            <div className="grid gap-5 py-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {TabProduct_CAP.flatMap((item, itemIndex) =>
                item.sources.map((source, sourceIndex) => (
                  <ThumbnailViewer
                    key={`${itemIndex}-${sourceIndex}`}
                    src={item.isVideo ? source.thumbnail : source.src} // Use thumbnail for videos
                    isVideo={item.isVideo}
                    hoverAble={true}
                    size="w-full h-[180px] rounded-[20px]"
                    onClick={() => {
                      if (item.isVideo) {
                        window.open(source.src, "_blank", "noopener,noreferrer"); // Open YouTube video in a new tab
                      } else {
                        setIndexVideoCAP(sourceIndex + itemIndex); // Open image in lightbox
                      }
                    }}
                  />
                ))
              )}
            </div>

            {/* Lightbox to display full-size images */}
            {indexVideoCAP !== -1 && (
              <Lightbox
                open={indexVideoCAP >= 0}
                close={() => setIndexVideoCAP(-1)} // Correct state update to close the lightbox
                index={indexVideoCAP}
                slides={TabProduct_CAP.flatMap((item) =>
                  item.sources.map((source) => ({
                    src: source.src,
                    type: item.isVideo ? "video" : "image",
                  }))
                )}
              />
            )}
          </Reveal>
          <Reveal className="p-5 flex flex-col gap-3">
            <p className="text-24-600 text-neutral-600">Documents</p>
            {TabProduct_CAPDocuments.map((item, index) => (
              <div className="w-fit">
                <Link
                  to={item.href}
                  target="_blank"
                  className="md:text-outline-hover  rounded-xl p-3 text-16-600-20 text-primary-700 w-fit "
                >
                  {item.title}
                </Link>
              </div>
            ))}
            <div className=" gap-5"></div>
          </Reveal>
        </div>
      </section>
      <script src="https://unpkg.com/lucide@latest"></script>
      <script>lucide.createIcons();</script>
    </div>
  );
};

export default ProductPage;
