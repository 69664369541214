import React from "react";
import { Link } from "react-router-dom"; // For navigation
import FooterLogo from "../assets/footer-logo";

const Footer = ({ isNavbar = false }) => {
  return (
    <div className="bg-white text-gray-800">
      {/* Top Decorative Bar */}
      <div
        className={`flex flex-row items-center gap-3 ${isNavbar && "hidden"}`}
      >
        <div className="flex-grow h-3 rounded-r-[4px] bg-primary-700" />
        <div className="w-3 h-3 rounded-[4px] bg-error-200" />
        <div className="w-3 h-3 rounded-l-[4px] bg-error-200" />
      </div>

      {/* Main Footer Content */}
      <div className="flex flex-col md:flex-row gap-11 lg:justify-between lg:items-center py-10 md:px-[5vw] px-5">
        {/* Footer Logo */}
        <div className="w-full md:w-fit flex justify-center">
          <FooterLogo />
        </div>

        {/* Sitemap */}
      {!isNavbar && (
        <div className="flex flex-col gap-4">
          <p className="text-lg font-semibold">Sitemap</p>
          <ul className="flex flex-col gap-2 text-base">
            <li>
              <Link
                to="/"
                onClick={() => window.scrollTo(0, 0)}
                className="hover:text-primary-700"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/product"
                onClick={() => window.scrollTo(0, 0)}
                className="hover:text-primary-700"
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => window.scrollTo(0, 0)}
                className="hover:text-primary-700"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={() => window.scrollTo(0, 0)}
                className="hover:text-primary-700"
              >
                Contact
              </Link>
            </li>
            <li>
              <a
                href="https://blog.dudaji.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary-700"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
        )}

        {/* Company Info */}
        <p className="text-center font-semibold">
          Dudaji, Inc © All Rights Reserved.
        </p>

        {/* Address and Email */}
        <div className="flex flex-col align-top justify-start items-start lg:p-3 gap-2 text-[16px] leading-5">
          <div className="flex flex-row items-left gap-1">
            <div className="mt-1 h-3 min-w-3 rounded-[4px] bg-primary-700" />
            <p className="text-wrap">
              서울특별시 관악구 남부순환로 218길 36(문성빌딩) 6층 두다지
            </p>
          </div>
          <div className="flex items-left align-middle gap-1">
            <div className="mt-1 size-3 rounded-[4px] bg-secondary" />
            <p className="text-16-400-20">info@dudaji.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
